exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aboutus-gobal-presence-js": () => import("./../../../src/pages/aboutus/gobal-presence.js" /* webpackChunkName: "component---src-pages-aboutus-gobal-presence-js" */),
  "component---src-pages-aboutus-timeline-js": () => import("./../../../src/pages/aboutus/timeline.js" /* webpackChunkName: "component---src-pages-aboutus-timeline-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sectors-contract-manufacturing-js": () => import("./../../../src/pages/sectors/contract-manufacturing.js" /* webpackChunkName: "component---src-pages-sectors-contract-manufacturing-js" */),
  "component---src-pages-sectors-engineering-js": () => import("./../../../src/pages/sectors/engineering.js" /* webpackChunkName: "component---src-pages-sectors-engineering-js" */),
  "component---src-pages-sectors-gloves-js": () => import("./../../../src/pages/sectors/gloves.js" /* webpackChunkName: "component---src-pages-sectors-gloves-js" */),
  "component---src-pages-sectors-leisure-js": () => import("./../../../src/pages/sectors/leisure.js" /* webpackChunkName: "component---src-pages-sectors-leisure-js" */),
  "component---src-pages-sectors-packaging-js": () => import("./../../../src/pages/sectors/packaging.js" /* webpackChunkName: "component---src-pages-sectors-packaging-js" */),
  "component---src-pages-sectors-plantations-js": () => import("./../../../src/pages/sectors/plantations.js" /* webpackChunkName: "component---src-pages-sectors-plantations-js" */),
  "component---src-pages-sectors-renewable-energy-js": () => import("./../../../src/pages/sectors/renewable-energy.js" /* webpackChunkName: "component---src-pages-sectors-renewable-energy-js" */),
  "component---src-pages-sectors-rubber-products-js": () => import("./../../../src/pages/sectors/rubber-products.js" /* webpackChunkName: "component---src-pages-sectors-rubber-products-js" */),
  "component---src-pages-sectors-thread-fabric-js": () => import("./../../../src/pages/sectors/thread&fabric.js" /* webpackChunkName: "component---src-pages-sectors-thread-fabric-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-sustainability-lalith-hapangama-foundation-dedicated-health-care-initiatives-js": () => import("./../../../src/pages/sustainability/lalith-hapangama-foundation/dedicated-health-care-initiatives.js" /* webpackChunkName: "component---src-pages-sustainability-lalith-hapangama-foundation-dedicated-health-care-initiatives-js" */),
  "component---src-pages-sustainability-lalith-hapangama-foundation-infrastructure-redevelopment-projects-js": () => import("./../../../src/pages/sustainability/lalith-hapangama-foundation/infrastructure-redevelopment-projects.js" /* webpackChunkName: "component---src-pages-sustainability-lalith-hapangama-foundation-infrastructure-redevelopment-projects-js" */),
  "component---src-pages-sustainability-lalith-hapangama-foundation-js": () => import("./../../../src/pages/sustainability/lalith-hapangama-foundation.js" /* webpackChunkName: "component---src-pages-sustainability-lalith-hapangama-foundation-js" */),
  "component---src-pages-sustainability-lalith-hapangama-foundation-supply-and-conservation-of-safe-water-js": () => import("./../../../src/pages/sustainability/lalith-hapangama-foundation/supply-and-conservation-of-safe-water.js" /* webpackChunkName: "component---src-pages-sustainability-lalith-hapangama-foundation-supply-and-conservation-of-safe-water-js" */),
  "component---src-pages-sustainability-lalith-hapangama-foundation-supporting-education-js": () => import("./../../../src/pages/sustainability/lalith-hapangama-foundation/supporting-education.js" /* webpackChunkName: "component---src-pages-sustainability-lalith-hapangama-foundation-supporting-education-js" */),
  "component---src-pages-sustainability-leaf-js": () => import("./../../../src/pages/sustainability/leaf.js" /* webpackChunkName: "component---src-pages-sustainability-leaf-js" */)
}

